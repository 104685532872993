.container {
  margin-top: 20px;
}

.form-label {
  font-weight: bold;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) {
  .chart-container {
    width: 800px;
    height: 800px;
  }
}
@media (min-width: 768px) {
  .text-below-chart {
    display: none;
  }
}
@media (max-width: 508px) {
  .text-below-calculator {
    display: none;
  }
}